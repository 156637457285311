import React from 'react';

class Welcome extends React.PureComponent {

  render() {
    return (
      <div style={{fontSize: '20px', fontWeight: 'bold'}}>Welcome</div>
    );
  }

}

export default Welcome;
